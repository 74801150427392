import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Carousel as TemplateCarousel } from '@catalogo/theme-carousel-with-bullets';
import {
  currentItemEffect,
  firstRenderEffect,
  handleUpdateSlide,
} from '@catalogo/ui-americanas-mobile-zion-slideshow/src/helpers';

const ZionSlideshow = ({ publication, renderComponent }) => {
  const { autoplay, children, arrow, publicationId, infinit, onLoad } = publication;
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);

  const triggerNextPage = () => {
    !!carouselRef.current && carouselRef.current.handlePressNext();
  };

  useEffect(firstRenderEffect(autoplay, triggerNextPage, onLoad), []);

  useEffect(currentItemEffect(children, currentSlide), [currentSlide]);

  return (
    <Wrapper>
      <TemplateCarousel
        ref={carouselRef}
        arrow={arrow === 'sim' ? true : false}
        bullets={arrow === 'sim' ? true : false}
        autoplay={autoplay === 'sim' ? true : false}
        infinit={infinit === 'sim' ? true : false}
        handleUpdateSlide={handleUpdateSlide(setCurrentSlide)}
      >
        {children &&
          children.map(child => (
            <React.Fragment key={`${publicationId}-${child._id}`}>
              {renderComponent({ ...child, zionSlideShow: true })}
            </React.Fragment>
          ))}
      </TemplateCarousel>
    </Wrapper>
  );
};

ZionSlideshow.propTypes = {
  publication: PropTypes.object,
  renderComponent: PropTypes.func,
};

export default ZionSlideshow;

const Wrapper = styled.div`
  margin-bottom: 22px;
  & .spacey-image {
    width: 1250px;
  }
`;
