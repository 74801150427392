import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';
import { bindAll } from '@catalogo/core-bind-all';
import { withMetrics } from '@catalogo/core-metrics';

import Arrow from '../src/icons/arrow';

export class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.shelfRef = React.createRef();
    this.childrenRef = React.createRef();
    this.state = {
      left: 0,
      carouselWidth: 0,
      maxLeft: 0,
      pages: 1,
      currentPage: 0,
      itemsToShow: props.itemsToShow || 0,
      firstTimeUsingNextBtn: true,
    };

    bindAll(this, ['handlePressNext', 'handlePressPrev', 'handleAnimate']);
  }

  componentDidMount() {
    const element = this.shelfRef.current;
    this.setState({
      carouselWidth: element.offsetWidth,
      itemsToShow: this.props.itemsToShow,
    });

    element.addEventListener(
      'scroll',
      debounce(event => {
        const left = event.srcElement.scrollLeft;
        this.setState({
          left,
          currentPage: Math.ceil(left === 0 ? 0 : left / element.offsetWidth),
        });
      }, 100)
    );
  }

  componentDidUpdate(_, prevState) {
    const shelf = this.shelfRef.current;
    const children = this.childrenRef.current;
    const pages = Math.ceil(children.offsetWidth / shelf.offsetWidth);
    const totalWidth = children.offsetWidth;
    const maxLeft = Math.max(totalWidth - shelf.offsetWidth, 0);

    if (typeof this.props.handleUpdateSlide === 'function') {
      this.props.handleUpdateSlide(this.state.currentPage);
    }

    if (prevState.maxLeft !== maxLeft) {
      this.setState({
        maxLeft,
        pages,
      });
    }
  }

  restartCarousel(arrow) {
    const { left, currentPage, pages, maxLeft } = this.state;
    const { infinit } = this.props;
    if (currentPage + 1 === pages && arrow == 'next' && infinit && left === maxLeft) {
      return 0;
    }

    if (currentPage + 1 === 1 && arrow == 'prev' && infinit && left === 0) {
      return maxLeft;
    }

    return left;
  }

  handleAnimate(arrow) {
    const element = this.shelfRef.current;

    const left = this.restartCarousel(arrow);

    element.scrollTo({
      left,
      behavior: 'smooth',
    });
  }

  handlePressNext(click) {
    const left = Math.min(this.state.left + this.state.carouselWidth, this.state.maxLeft);

    if (this.props.isBannerVitrineAds && this.state.firstTimeUsingNextBtn) {
      this.setState({
        ...this.state,
        firstTimeUsingNextBtn: false,
      });

      this.props.dispatchMetrics('event:track', {
        category: 'categoria',
        action: 'banner-patrocinado-vitrine',
        label: 'navegacao-lateral',
      });
    }

    if (click || this.state.autoplay) {
      this.setState(
        {
          left,
        },
        () => this.handleAnimate('next')
      );
    }
  }

  handlePressPrev(click) {
    const left = Math.max(this.state.left - this.state.carouselWidth, 0);
    const { currentPage } = this.state;

    if (click || this.state.autoplay) {
      if (currentPage === 0) {
        this.handleAnimate('prev');
      }

      this.setState(
        {
          left,
        },
        () => this.handleAnimate('prev')
      );
    }
  }

  renderPrevButton() {
    const { arrow, infinit } = this.props;

    if (!arrow) {
      return null;
    }

    if (this.state.left === 0 && !infinit) {
      return null;
    }

    return (
      <Prev feedback="false" onClick={this.handlePressPrev}>
        <Arrow direction="left" />
      </Prev>
    );
  }

  renderNextButton() {
    const { arrow, infinit } = this.props;

    if (!arrow) {
      return null;
    }

    if (Math.ceil(this.state.left) >= this.state.maxLeft && !infinit) {
      return null;
    }

    return (
      <Next feedback="false" onClick={this.handlePressNext}>
        <Arrow direction="right" />
      </Next>
    );
  }

  renderBullets() {
    const { bullets, bulletStyledComponent } = this.props;

    if (!bullets) {
      return null;
    }

    const { currentPage, pages } = this.state;
    const items = [...Array(pages)];

    if (items.length === 1) {
      return null;
    }

    const { bullet, current } = bulletStyledComponent;

    const BulletComponent = bullet ? bullet : Bullet;
    const BulletCurrentComponent = current ? current : BulletCurrent;

    return (
      <View>
        <Bullets>
          {items.map((_, index) => (
            <BulletComponent key={`bullet-${index.toString()}`} />
          ))}
          <BulletCurrentComponent position={currentPage} />
        </Bullets>
      </View>
    );
  }

  toggleAutoplay(autoplay, status) {
    return !!autoplay && this.setState({ ...this.state, autoplay: status });
  }

  mountMargin(width, itemWidth, itemsToShow) {
    const diff = width - itemWidth * itemsToShow;
    const margin = diff / itemsToShow;
    const valueMargin = (diff + margin) / itemsToShow;
    return valueMargin > 0 ? valueMargin : 0;
  }

  getSpacingBetween() {
    const { itemsToShow, carouselWidth } = this.state;

    if (!this.shelfRef || !this.shelfRef.current) {
      return 0;
    }

    const containerDOM = this.shelfRef.current.children[0];
    const itemWidth = containerDOM.children[0].clientWidth;
    const totalChildren = containerDOM.children;

    if (itemsToShow > totalChildren.length) {
      return this.mountMargin(carouselWidth, itemWidth, totalChildren.length);
    }

    return this.mountMargin(carouselWidth, itemWidth, itemsToShow);
  }

  getGap(index, children) {
    const { itemsToShow, gap } = this.props;
    if (index === children.length - 1) {
      return 0;
    }
    if (itemsToShow) {
      return this.getSpacingBetween();
    }
    return gap;
  }

  render() {
    const { spacing, children, autoplay = true, marginCategory, className } = this.props;

    return (
      <Wrapper
        className={className}
        spacing={spacing}
        onMouseEnter={() => this.toggleAutoplay(autoplay, false)}
        onMouseLeave={() => this.toggleAutoplay(autoplay, true)}
      >
        <CollectionUI horizontal={true} spacing={null} ref={this.shelfRef}>
          <WrapperItems ref={this.childrenRef} marginCategory={marginCategory}>
            {React.Children.map(children, (child, index) => (
              <Snap className="card" key={child.key} gap={this.getGap(index, children)}>
                {child}
              </Snap>
            ))}
          </WrapperItems>
        </CollectionUI>
        {this.renderPrevButton()}
        {this.renderNextButton()}
        {this.renderBullets()}
      </Wrapper>
    );
  }
}

export default withMetrics(Carousel);

const View = styled.div`
  display: flex;
  position: relative;
`;

const Navigator = styled.button`
  display: flex;
  position: relative;
  background: none;
  border: none;
`;

const Wrapper = styled(View)`
  flex-direction: column;
`;

const Snap = styled(View)`
  scroll-snap-align: start;
  margin-right: ${props => `${props.gap}px`};
`;

const Bullets = styled(View)`
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const BulletBase = styled(View)`
  width: 6px;
  height: 6px;
  margin: 0 5px;
  border-radius: 100px;
`;

export const Bullet = styled(BulletBase)`
  background: #000;
  opacity: 0.5;
`;

export const BulletCurrent = styled(BulletBase)`
  position: absolute;
  transition: transform 0.3s;
  transform: ${props => `translateX(${props.position * 16}px)`};
  left: 0;
  background: #333;
`;

const WrapperArrow = styled(Navigator)`
  z-index: 2;
  position: absolute;
  width: 65px;
  height: 65px;
  align-items: center;
  background: #fff;
  opacity: 0.6;
  justify-content: center;
  top: 50%;
  margin-top: -32px;
  border-radius: 50px;

  & svg use {
    fill: #ccc;
  }
  &:hover {
    & svg use {
      fill: #fff;
    }
    opacity: 1;
    background: #ccc;
  }
`;

const Next = styled(WrapperArrow)`
  right: -32px;
  cursor: pointer;
`;

const Prev = styled(WrapperArrow)`
  left: -32px;
  cursor: pointer;
`;

const WrapperItems = styled(View)`
  flex-direction: row;
  float: left;
  ${({ marginCategory }) =>
    marginCategory &&
    css`
      margin: ${marginCategory};
    `}
`;

const CollectionUI = styled(View)`
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  display: flex;
  flex-direction: row;
  -webkit-appearance: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .spacey-image,
  .spacey-image > a,
  .spacey-image > a > div {
    height: 100%;
  }
`;

Carousel.defaultProps = {
  arrow: true,
  bulletStyledComponent: { bullet: '', current: '' },
};

Carousel.propTypes = {
  spacing: PropTypes.number,
  gap: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  bullets: PropTypes.bool,
  arrow: PropTypes.bool,
  bulletStyledComponent: PropTypes.object,
};
