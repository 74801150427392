import React from 'react';
import { Carousel } from '@catalogo/theme-carousel-with-bullets';
import styled, { css } from 'styled-components';
import PropType from 'prop-types';

const ZionImageCarousel = ({ publication, renderComponent }) => {
  const { componentTitle, publicationId, itemsToShowXL, widthCard } = publication;

  return (
    <Wrapper widthCard={widthCard}>
      {componentTitle && <Title>{componentTitle}</Title>}
      <Carousel itemsToShow={itemsToShowXL}>
        {publication.children.map(child => (
          <React.Fragment key={`${publicationId}-${child.linkUrl}`}>
            {renderComponent({ showTitle: publication.showTitles === 'sim', ...child })}
          </React.Fragment>
        ))}
      </Carousel>
    </Wrapper>
  );
};

ZionImageCarousel.propTypes = {
  publication: PropType.object,
  renderComponent: PropType.func,
};

const Wrapper = styled.div`
  & .spacey-image {
    ${props =>
      css`
        width: ${props.widthCard || 144}px;
        margin-right: 25px;
      `}
    & + .spacey-image {
      padding-left: 16px;
    }
  }
  margin-bottom: 15px;
`;

const Title = styled.h2`
  ${({ theme }) => css`
    text-transform: ${theme.titleTransform};
    font-weight: bold;
    font-size: 26px;
    color: ${({ theme }) => theme.fontColor || theme.titleColor};
    padding: 20px 0px 10px 10px;
  `};
`;

export default ZionImageCarousel;
