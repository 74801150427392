export const currentItemEffect = (children, currentSlide) => () => {
  const onViewEvent = children?.[currentSlide]?.onView;
  if (onViewEvent) {
    onViewEvent();
  }
};

export const firstRenderEffect = (autoplay, triggerNextPage, onLoad) => () => {
  if (autoplay === 'sim') {
    setInterval(() => triggerNextPage(), 4000);
  }

  if (typeof onLoad === 'function') {
    onLoad();
  }
};

export const handleUpdateSlide = setCurrentSlide => index => setCurrentSlide(index);
