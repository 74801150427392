import React from 'react';
import PropTypes from 'prop-types';
import ArrowIcon from '@catalogo/ui-americanas-desktop-svg/chevron-right.svg';

const directions = {
  right: {
    transform: 'rotate(0)',
  },
  left: {
    transform: 'rotate(180)',
  },
  up: {
    transform: 'rotate(-90)',
  },
  down: {
    transform: 'rotate(90)',
  },
};

const Arrow = ({ direction = 'down', width = '25px', height = '25px' }) => (
  <ArrowIcon width={width} height={height} fill="#f80032" transform={directions[direction].transform} />
);

Arrow.propTypes = {
  direction: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Arrow;
